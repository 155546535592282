import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import theme from '../constants/theme'

import 'bootstrap/dist/css/bootstrap.min.css'

import Navbar from './Navbar'
import Footer from './Footer'

const Wrapper = styled.div`
  max-width: ${theme.sizes.contentWidth};
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: `Cabin rental in Lofoten with an excellent view of 
              the surrounding mountains. Five available cabins located by 
              the sea, with short walking distance to the local store.`,
            },
            {
              name: 'keywords',
              content: `cabin, rental, lofoten, rorbu, accomodation, camping, 
                kitchen, parking, view, reine, moskenes`,
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <Navbar />
        <Wrapper className="container col-12 col-lg-10 col-xl-9 main-container">
          <div>{children}</div>
        </Wrapper>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
