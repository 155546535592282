import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 0px;
  display: flex;
  justify-content: center;
  margin: 1.2em 0;
`

const StyledText = styled.p`
  margin: 0px;
  text-align: center;
  /* padding: 1.4em 0; */

  color: #6c757d; /* Muted text */
`

const Footer = props => {
  return (
    <Wrapper>
      <StyledText>
        Møllers Rorbuer Lofoten &copy; <br />
        Design delivered by <a href="https://ofstad.io">ofstad.io</a>
      </StyledText>
    </Wrapper>
  )
}

Footer.propTypes = {}

export default Footer
