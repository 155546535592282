export default {
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  sizes: {
    contentWidth: '1000px',
    cardHeight: '300px',
  },
  colors: {},
}
