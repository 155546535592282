import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  Button,
  Collapse,
  NavbarToggler,
  Nav,
  NavItem,
  Navbar as RSNavbar,
} from 'reactstrap'
import theme from '../constants/theme'

const Wrapper = styled.div`
  max-width: ${theme.sizes.contentWidth};
`

const StyledNavItem = styled(NavItem)`
  margin-left: 0.8rem;
`

const StyledCollapse = styled(Collapse)`
  @media (max-width: ${theme.breakpoints.sm}) {
    text-align: center;
    margin-top: 0.5rem;
    ${StyledNavItem} {
      margin-bottom: 0.5rem;
    }
  }
`

class Navbar extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <RSNavbar className="navbar navbar-expand-sm px-0 navbar-dark bg-dark shadow">
        <Wrapper className="container px-3">
          <Link className="navbar-brand" to="/">
            Møllers Rorbuer Lofoten
          </Link>
          <NavbarToggler onClick={this.toggle} />
          <StyledCollapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <StyledNavItem>
                <Link to="/">
                  <Button color="primary">Home</Button>
                </Link>
              </StyledNavItem>
              <StyledNavItem>
                <Link to="/cabins">
                  <Button color="primary">Our Cabins</Button>
                </Link>
              </StyledNavItem>
              {/* <StyledNavItem>
                <Button color="primary">Contact us to book a cabin</Button>
              </StyledNavItem> */}
            </Nav>
          </StyledCollapse>
        </Wrapper>
      </RSNavbar>
    )
  }
}

Navbar.propTypes = {}

export default Navbar
