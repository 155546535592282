import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/Layout'

const LocationPage = () => (
  <Layout>
      Location
  </Layout>
)

export default LocationPage
